// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js';

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import aboutUs from './routes/about';
import productErgo from './routes/product-ergo';
import productErgo2 from './routes/product-ergo2';
import productBench from './routes/product-benchmarks';
import productDevTools from './routes/product-devtools';
import markets from './routes/markets';
import careers from './routes/careers'; 
import news from './routes/news'; 
import contactUs from './routes/contact'; 
import videos from './routes/videos'; 
import media from './routes/media';
import awards from './routes/awards';
import smartHome from './routes/smart';
import companyNews from './routes/company';
import portalStart from './routes/start';
import portalLogin from './routes/login';


// import then needed Font Awesome functionality
import { library, dom } from '@fortawesome/fontawesome-svg-core';
// import the Facebook and Twitter icons
import { faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';

// add the imported icons to the library
library.add(faFacebook, faTwitter);

// tell FontAwesome to watch the DOM and add the SVGs when it detects icon markup
dom.watch();

/** Populate Router instance with DOM routes */
const routes = new Router({
  // All pages
  common,
  // Home page
  home,
  // About Us page, note the change from about-us to aboutUs.
  aboutUs,
  // Product page, Ergo
  productErgo,
  // Product page, Ergo 2
  productErgo2,
  // Product page, benchmarks
  productBench,
  // Product page, dev tools
  productDevTools,
  // Markets page, to replace the Solutions page.
  markets,
  // Careers page
  careers,
  // News and Updates page
  news,
  // Contact page
  contactUs,
  // Videos page
  videos,
  // Media page
  media,
  // Awards page
  awards,
  // Smart Home Report page
  smartHome,
  // Company News page
  companyNews,
  // Portal Start page
  portalStart,
  // Portal Login page
  portalLogin,
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());